import PropTypes from "prop-types";
import { toast } from "react-toastify";

export const displayIcon = (type) => {
  switch (type) {
    case "success":
      return "fas fa-check-circle";
    case "error":
      return "fas fa-exclamation-circle";
    case "info":
      return "fas fa-info-circle";
    case "warning":
      return "fas fa-exclamation-triangle";
    default:
      return "fas fa-info-circle";
  }
};
const Toast = ({ type, message }) => {
  toast[type](
    <div className="d-flex align-items-center">
      <div className="icon icon-sm icon-shape icon-shape-success shadow-success rounded-circle me-3">
        <i className={displayIcon(type)}></i>
      </div>
      <div>
        <h6 className="mb-0">{message}</h6>
      </div>
    </div>
  );
  return null;
};

Toast.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

Toast.dismiss = toast.dismiss;

export default Toast;
